#idea-icon-container {

  &:hover #idea-icon{
    fill: #77dd77;
  }
}

#pop-up {
  margin-top: 25px;
  font-size: 21px;
  text-align: center;
  animation: fadein 5s;
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }



