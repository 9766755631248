.group {
  position: relative;
  margin: 45px 0;

}

.form-input {
  background-color: #FBFBFB;
  color: #252525;
  font-size: 18px;
  padding: 10px 0 10px 13px;
  display: block;
  width: 100%;
  border: none;
  margin: 25px 10px 25px 0;
  box-sizing: border-box;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(255, 255, 255, 0.25);
  border-radius: 8px;
}

.group .form-input-area {
  background-color: #FBFBFB;
  color: #252525;
  font-size: 18px;
  padding: 10px 0 10px 13px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: none;
  margin: 25px 10px 25px 0;

  height: 100px;
  border-radius: 8px;
}
.group .form-input-area:focus {
  outline: none;
}
.group .form-input-area:focus ~ .form-input-label {
  top: -28px;
  left: 13px;
  font-size: 18px;
  color: white;
}
.group input[type=password] {
  letter-spacing: 0.3em;
}
.group .form-input-label {
  color: white;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 13px;
  top: -28px;
  transition: 300ms ease all;
}
.group .form-input-label.shrink {
  top: -14px;
  font-size: 12px;
  color: black;
}

.error-fill {
  color: white;
}

#spinner {
  width: 100%;
  height: 50px;
  background: #252525;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  font-weight: 900;

  cursor: pointer;
  margin-top: 0;


  &:active{
    box-shadow: inset 5px 5px 4px rgba(0, 0, 0, 0.3), inset -3px -3px 3px rgba(138, 138, 138, 0.3);
  }

  &:hover{
    background: #60b0de;
    color: #fbfbfb;
  }
}

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;

}

.fade-exit {
  opacity: 1;

}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;

}


