#dust {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: -1;
}

@keyframes zoomIn {
  0% {
    transform: scale(.3);
    filter: blur(10px);
  }
  40% {
    transform: scale(1);
  }
  100% {
    filter: blur(0);
    transform: scale(1);
  }
}

@mixin btn-border-drawing($color: #60b0de, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;

  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;

    #{$vertical}: 0;
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');

    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }

  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');

    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }

  &:hover {
    color: $hover;

    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }

    &::before { transition-delay: 0s, 0s, $duration; }
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#E3E3EB, #60b0de, 4px, bottom, right);
}

